<template>
  <div class="demand-show-desktop">
    <div
        v-if="demand.loading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>
    <v-card
        v-else
        height="100%">
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-file-document-outline"
            title="جزییات تقاضا"
        >
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-3"
                    icon
                    text
                    color="main"
                >
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              چاپ درخواست
            </v-tooltip>

            <v-btn
                @click="$router.back()"
                elevation="0"
                outlined
                color="main"
            >
              بازگشت
              <v-icon class="mr-2">
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </div>
        </PageTitle>
      </v-card-title>

      <v-card-text>
        <v-row dense class="mb-6">
          <v-col :cols="12">
            <div class="mb-2 font-weight-bold text-body-1 black--text">
              اطلاعات متقاضی :
            </div>
          </v-col>
          <v-col :cols="12">
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                نوع معامله :
              </div>
              <div class="demand-value">
                {{ demand.transactionType }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                نوع ملک :
              </div>
              <div class="demand-value">
                {{ demand.fileProperty }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                نام مراجعه کننده :
              </div>
              <div class="demand-value">
                {{ demand.username }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                شماره تماس :
              </div>
              <div class="demand-value">
                {{ demand.phoneNumber }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                مهلت زمان :
              </div>
              <div class="demand-value">
                {{ demand.deadline }}
              </div>
            </div>
          </v-col>
          <v-col :cols="12">

            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                طبقه ملک درخواستی :
              </div>
              <div class="demand-value">
                {{ demand.floorNumber }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                سن بنا :
              </div>
              <div class="demand-value">
                {{ demand.age }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                درصد نقدینگی:
              </div>
              <div class="demand-value">
                {{ demand.liquidityPercentage }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                تعداد نفرات :
              </div>
              <div class="demand-value">
                {{ demand.numberofpeople }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                محدوده متراژ ملک :
              </div>
              <div class="demand-value">
                {{ demand.baseArea }}
              </div>
            </div>
          </v-col>
          <v-col :cols="12">

            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                روزهای بازدید :
              </div>
              <div class="demand-value">
                {{ demand.visitDays }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                محدوده مساحت زمین :
              </div>
              <div class="demand-value">
                {{ demand.landArea }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                محدوده قیمت کل / رهن :
              </div>
              <div class="demand-value">
                {{ demand.totalPrice }}
              </div>
            </div>
            <div class="demand-item">
              <div class="demand-name">
                <v-icon color="#333" size="10">
                  mdi-circle
                </v-icon>
                محدوده قیمت متری / اجاره :
              </div>
              <div class="demand-value">
                {{ demand.pricePerMeter }}
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row class="mb-6" dense>
          <v-col :cols="12">
            <div class="mb-2 font-weight-bold text-body-1 black--text">
              امکانات ملک درخواستی :
            </div>
          </v-col>
          <v-col
              v-for="(property,index) in demand.properties"
              :key="index"
              :cols="2">
            <v-icon color="main" size="20">
              mdi-check-circle-outline
            </v-icon>
            <span class="font-weight-bold text-body-2" style="color: #333">
              {{ property.name }}
            </span>
          </v-col>
        </v-row>

        <v-row class="mb-6" dense>
          <v-col :cols="12">
            <div class="mb-4 font-weight-bold text-body-1 black--text">
              مناطق درخواستی و توضیحات :
            </div>
          </v-col>
          <v-col :cols="12">
            <v-chip
                class="ml-3"
                v-for="(location,index) in demand.locations"
                :key="index"
                color="main"
                text-color="white">
              <v-icon size="20">
                mdi-map-marker
              </v-icon>
              <span class="font-weight-bold text-body-2">
              {{ location.name }}
            </span>
            </v-chip>
          </v-col>
          <v-col :cols="12" class="mt-6">
            {{
              demand.description
            }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle"
import {find} from "@Newfiling/module-demand/src/api";
import {AppConfig} from "@Newfiling/Services";
import {addCommas} from "@persian-tools/persian-tools";

export default {
  name: 'DesktopJobCreate',

  components: {
    PageTitle,
  },

  data() {
    return {
      fileProperties: [],
      locations: [],
      transactionTypes: [],
      properties: [],
      demand: {
        loading: true,
        transactionType: null,
        fileProperty: null,
        username: null,
        phoneNumber: null,
        deadline:null,
        floorNumber: null,
        age: null,
        liquidityPercentage:null,
        numberofpeople:null,
        baseArea: null,
        visitDays:null,
        landArea: null,
        totalPrice: null,
        pricePerMeter: null,
        properties: [],
        locations: [],
        description: null,
      },
      daysOfWeek: [
        {
          id: 0,
          name: "شنبه"
        },
        {
          id: 1,
          name: "یکشنبه"
        },
        {
          id: 2,
          name: "دوشنبه"
        },
        {
          id: 3,
          name: "سه شنبه"
        },
        {
          id: 4,
          name: "چهار شنبه"
        },
        {
          id: 5,
          name: "پنج شنبه"
        },
        {
          id: 6,
          name: "جمعه"
        },
      ],
    }
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig) || {};
      this.locations = appConfig.neighborhoods.map(i => {
        return {
          id: i.Id,
          name: i.NeighborhoodName,
        }
      });
      this.properties = appConfig.DemandFeaturesTB.map(i => {
        return {
          id: i.Id,
          name: i.Persianname,
        }
      });
      this.fileProperties = appConfig.PropertyType.map(i => {
        return {
          id: i.Id,
          name: i.PropertyType,
        }
      });
      this.transactionTypes = appConfig.Typeoftransaction.map(i => {
        return {
          id: i.Id,
          name: i.Typeoftransaction,
        }
      });
      await this.fetchDemand(this.$route.params.id);
    })
  },

  methods: {
    async fetchDemand(id) {
      this.demand.loading = true;
      try {
        const res = (await find(id))?.data?.message || {};
        console.log({res})
        this.demand = {
          ...this.demand,
          transactionType: this.transactionTypes.find(i => i.id === res.typeofdemand)?.name,
          fileProperty: this.fileProperties.find(i => i.id === res.propertyType)?.name,
          username: res.clientName,
          phoneNumber: res.mobile,
          deadline: res.deadline + ' روز ',
          liquidityPercentage: res.liquidityPercentage + ' % ',
          numberofpeople: res.numberofpeople + ' نفر',
          visitDays:this.daysOfWeek.find(i=>i.id===res.startdayTheDayOfTheVisit)?.name +' تا '+ this.daysOfWeek.find(i=>i.id===res.enddayTheDayOfTheVisit)?.name  ,
          baseArea: `${res.minmummetraj} تا ${res.maximummetraj} متر مربع `,
          landArea: `${res.landarea1} تا ${res.landarea2} متر مربع `,
          floorNumber: `${res.floornumber1} تا ${res.floornumber2}`,
          age: `${res.minimumbuildingage} تا ${res.maximumbuildingage} سال `,
          properties: this.properties.filter(i => res.attributes.split('-').map(j => parseInt(j)).includes(i.id)),
          locations: this.locations.filter(i => [res.locationsid1, res.locationsid2, res.locationsid3].includes(i.id)),
          description: res.description,
          totalPrice: `${addCommas(res.minimumSalesBudget)} تا ${addCommas(res.maximumSalesBudget)} تومان `,
          pricePerMeter: `${addCommas(res.minimumRentBudget)} تا ${addCommas(res.maximumRentBudget)} تومان `,
        }
      } catch (e) {
        console.log(e)
      }
      this.demand.loading = false;
    },
    async submitReport() {
      this.report.loading = true;
      try {
        await submitReport({
          ...this.report,
          fileid:this.$route.params.id
        });
        this.$toast.success('گزارش شما با موفقیت ثبت شد.');
        this.report = {
          ...this.report,
          modal: false,
          fileid: null,
          thisfileissold: null,
          thisfileisrented: null,
          thepricehaschanged: null,
          messagetext: null,
        }
      } catch (e) {
        console.log(e)
      }
      this.report.loading = false;
    }
  }


}
</script>

<style>
.demand-name {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
}

.demand-value {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}

.demand-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}
</style>